import { useParams } from "@remix-run/react"
import { z } from "zod"

export enum ViewMode {
  Feed = "feed",
  Table = "table",
}

export const useViewMode = () => {
  const params = useParams()

  if ("viewMode" in params && typeof params.viewMode === "string") {
    const result = z.nativeEnum(ViewMode).safeParse(params.viewMode)

    if (result.success) {
      return result.data
    }
  }

  return ViewMode.Feed
}
