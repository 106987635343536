import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"

function useSessionStorageState() {
  const [state, setState] = useState<Record<string, string>>({})

  useEffect(() => {
    setState({ ...sessionStorage })
  }, [])

  const setWithSessionStorage = (key: string, nextState: string) => {
    if (nextState !== "") sessionStorage.setItem(key, nextState)
    else sessionStorage.removeItem(key)

    setState({ ...sessionStorage })
  }

  return [state, setWithSessionStorage] as const
}

type SessionStorage = {
  sessionStorage: Record<string, string>
  setSessionStorage: (key: string, value: string) => void
}
const SessionStorageContext = createContext<SessionStorage>({
  sessionStorage: {},
  setSessionStorage: () => {},
})

export const SessionStorageProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [sessionStorage, setSessionStorage] = useSessionStorageState()

  return (
    <SessionStorageContext.Provider
      value={{ sessionStorage, setSessionStorage }}
    >
      {children}
    </SessionStorageContext.Provider>
  )
}

export function useSessionStorage(key: string) {
  const { sessionStorage, setSessionStorage } = useContext(
    SessionStorageContext
  )

  return [
    sessionStorage[key],
    (value: string) => setSessionStorage(key, value),
  ] as const
}
