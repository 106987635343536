import { SortDirection } from "./db/queryBuilder"

export const updateSearchParams = <
  TQuery extends Record<string, any>,
  TSort extends Record<string, SortDirection>
>(
  searchParams: URLSearchParams,
  options: {
    query?: TQuery
    sort?: TSort
  }
) => {
  const query = options.query ?? JSON.parse(searchParams.get("query") ?? "{}")
  const sort = options.sort ?? JSON.parse(searchParams.get("sort") ?? "{}")
  const search = searchParams.get("search")

  const hasQuery = query && Object.keys(query).length > 0
  const hasSort = sort && Object.keys(sort).length > 0

  const params = {
    ...(hasQuery ? { query: JSON.stringify(query) } : search && { search }),
    ...(hasSort && { sort: JSON.stringify(sort) }),

    // If new search params have no query, then ignore all the edit query params
    ...(hasQuery && updatedEditQueryParams(searchParams)),
  }

  return new URLSearchParams(params)
}

export const updatedEditQueryParams = (searchParams: URLSearchParams) => {
  const params: Record<string, string> = {}

  const searchId = searchParams.get("searchId")

  if (searchId) {
    params["searchId"] = String(searchId)
  }

  return params
}
