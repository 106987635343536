import { SpecterProducts } from "@prisma/client"
import { AsyncInputProperty } from "~/routes/__protected/api/input-options.$product.$property"
import { getProductSignalSearchParams } from "./hooks/useProductFilters"
import { ViewMode } from "./hooks/useViewMode"

// Workaround for URLSearchParams not having a size property
// Open issue to fix this:
// https://github.com/microsoft/TypeScript/issues/54466
declare global {
  interface URLSearchParams {
    size: number
  }
}

export const cacheKeys = {
  affinityCheckSignal: (product: string, id: string) => [
    "affinityCheckSignal",
    product,
    id,
  ],
  affinityToken: ["affinityToken"],
  tokenVerify: ["tokenVerify"],

  userIsTeamAdmin: ["userIsTeamAdmin"],
  sidebarState: ["sidebarState"],

  talentSignal: (id: string) => ["talentSignals", id],
  strategicSignal: (id: string) => ["strategicSignals", id],
  companyStats: () => ["companyStats"],
  companySignal: (id: string) => ["companySignals", id],
  companyManualEnrichment: () => ["companyManualEnrichment"],
  companySignalsSortParam: ["companySignalsSortParam"],
  signals: (
    product: SpecterProducts,
    searchParams: URLSearchParams,
    viewMode: ViewMode = ViewMode.Table
  ): any[] => {
    if (searchParams.has("searchId") && !searchParams.has("edit")) {
      return [
        "signals",
        product,
        searchParams.get("searchId")?.toString(),
        searchParams.get("sort")?.toString(),
        viewMode,
      ]
    }

    if (searchParams.has("search") && !searchParams.has("query")) {
      return [
        "signals",
        product,
        searchParams.get("search")?.toString(),
        searchParams.get("sort")?.toString(),
        viewMode,
      ]
    }

    return [
      "signals",
      product,
      getProductSignalSearchParams(product, searchParams),
      searchParams.get("sort")?.toString(),
      viewMode,
    ]
  },
  signalsCount: ({
    product,
    searchParams,
    listId,
  }: {
    product: SpecterProducts
    searchParams: URLSearchParams
    listId?: string
  }): any[] => {
    if (searchParams.has("search")) {
      return [
        "signals",
        `${product}-count`,
        searchParams.get("search")?.toString(),
      ]
    }

    return [
      "signals",
      `${product}-count`,
      getProductSignalSearchParams(product, searchParams),
      ...(listId ? [listId] : []),
    ]
  },
  userSessions: ["userSessions"],
  emailVerification: ["emailVerification"],

  userSavedSearches: (product: SpecterProducts) => [
    "userSavedSearches",
    product,
  ],
  userSavedSearch: (id: string): [string, string] => ["userSavedSearch", id],
  userSearchDetail: (id: number) => ["userSearchDetail", id],
  signalFavourite: (id: string) => ["signalFavourite", id],
  signalAddedToList: (id: string) => ["signalAddedToList", id],

  userPermissions: ["userPermissions"],

  userLists: (
    product: SpecterProducts,
    signalId?: string,
    isDashboard?: boolean
  ) => ["userLists", product, signalId, !!isDashboard],
  userList: (listId: string, searchParams?: URLSearchParams) => [
    "userList",
    listId,
    ...(searchParams && searchParams.size > 0 ? [searchParams.toString()] : []),
  ],
  userListDetails: (listId: string) => ["userListDetails", listId],

  globalHubLists: (product: SpecterProducts) => ["globalHubLists", product],
  globalHubSearches: (product: SpecterProducts) => [
    "globalHubSearches",
    product,
  ],

  talentThisWeek: ["talentThisWeek"],
  strategicThisWeek: ["strategicThisWeek"],

  integrations: ["integrations"],
  integrationsDataSourceInstance: (
    integrationId: string,
    dataSourceKey: string
  ) => ["integrationsDataSourceInstance", integrationId, dataSourceKey],
  integrationsFieldMappingInstance: (
    integrationId: string,
    fieldMappingKey: string
  ) => ["integrationsFieldMappingInstance", integrationId, fieldMappingKey],
  integrationsDataCollections: (key: string) => [
    "integrationsDataCollections",
    key,
  ],
  integrationsFieldMappingIsSetup: (fieldMappingKey?: string) => [
    "integrationsFieldMappingIsSetup",
    fieldMappingKey,
  ],
  integrationsDataCollectionList: (...opts: Array<string | undefined>) => [
    "integrationsDataCollectionList",
    ...opts,
  ],
  integrationLists: (integrationKey?: string) => [
    "integrationsLists",
    integrationKey,
  ],
  integrationsFlowRuns: ["integrationsFlowRuns"],
  integrationsFlow: (key: string | null) => ["integrationsFlow", key],
  integration: (key: string) => ["integration", key],
  integrationMeta: (key?: string) => ["integrationMeta", key],
  userAllIntegrationCompanies: (searchParams?: URLSearchParams) => [
    "userAllIntegrationCompanies",
    searchParams?.toString(),
  ],
  clientsIntegrationCompanies: (key?: string) => [
    "clientsIntegrationCompaniesStatus",
    key,
  ],

  zapier: ["zapier"],

  teamMembers: (query: string = "") => ["teamMembers", query],
  shareMembers: ["shareMembers"],

  adminUsers: (query: string = "") => ["adminUsers", query],
  adminCompanies: (query: string = "") => ["adminCompanies", query],
  adminCompanyDetail: (id: string = "") => ["adminCompanyDetail", id],
  adminClients: (query: string = "") => ["adminClients", query],

  topSignals: (
    product: SpecterProducts,
    leftToggle: number,
    rightToggle: number
  ) => ["topSignals", product, leftToggle, rightToggle],

  searchesSummary: (product: SpecterProducts) => ["searchesSummary", product],
  newSignalsOverTime: (product: SpecterProducts) => [
    "newSignalsOverTime",
    product,
  ],
  suggestedSearchesMenu: (section: string) => [
    "suggestedSearchesMenu",
    section,
  ],
  companyDeliveryMonth: ["companyDeliveryMonth"],
  investor: (id: string) => ["investor", id],
  inputOptions: (property: AsyncInputProperty) => ["inputOptions", property],

  tableViews: (product: SpecterProducts) => ["tableViews", product],

  investorStrategicSignals: (id: string) => ["investorStrategicSignals", id],
} as const
