import { useFormik } from "formik"
import * as R from "ramda"
import { toFormikValidationSchema } from "zod-formik-adapter"
import { useSafeSearchParams } from "~/utils/hooks/useSafeSearchParams"

import { SpecterProducts } from "@prisma/client"
import {
  PRODUCT_SIGNAL_FILTERS_VALIDATION,
  SignalFilters,
} from "~/components/Filters/schemas"
import { JSONSafeParse } from "../JSONSafeParse"

import { updateSearchParams } from "../updateSearchParams"

interface Props<T extends SpecterProducts> {
  product: T
  onSubmit?: () => void
}

export const getProductSignalSearchParams = <T extends SpecterProducts>(
  product: T,
  searchParams: URLSearchParams
) => {
  const param = searchParams.get("query")
  const query = JSONSafeParse(param) as Record<string, any>

  const signalFiltersValidation = PRODUCT_SIGNAL_FILTERS_VALIDATION[product]
  const result = signalFiltersValidation.safeParse(query)

  if (!result.success) {
    const cleanQuery = R.omit(
      result.error.issues.map((issue) => String(issue.path[0])),
      query
    )

    const cleanResult = signalFiltersValidation.safeParse(cleanQuery)

    return (cleanResult.success ? cleanResult.data : {}) as SignalFilters[T]
  }

  return result.data as SignalFilters[T]
}

export const useProductSignalFilters = <T extends SpecterProducts>({
  product,
  onSubmit,
}: Props<T>) => {
  const [searchParams, setSearchParams] = useSafeSearchParams()

  return useFormik<SignalFilters[T]>({
    enableReinitialize: true,
    initialValues: getProductSignalSearchParams(product, searchParams),
    // @ts-ignore
    validationSchema: toFormikValidationSchema(
      PRODUCT_SIGNAL_FILTERS_VALIDATION[product]
    ),
    onSubmit(query) {
      onSubmit?.()

      const appliedFilters = R.reject(R.isEmpty, query)

      const newSearchParams = updateSearchParams(searchParams, {
        query: appliedFilters,
      })

      setSearchParams(newSearchParams)
    },
  })
}
