import { SpecterProducts } from "@prisma/client"
import { useSearchParams } from "@remix-run/react"
import { useProduct } from "./useProduct"
import { useSessionStorage } from "./useSessionStorage"
import { useMemo } from "react"

export type URLSearchParamsInit =
  | string
  | URLSearchParams
  | Record<string, string>

const MAXIMUM_URL_LENGTH = 2000

export function useSafeSearchParams(product?: SpecterProducts) {
  const [searchParams, setSearchParams] = useSearchParams()
  const useProductRes = useProduct({ enforce: false })

  const _product = product ?? useProductRes ?? "app"

  const [query, setQuery] = useSessionStorage(`${_product}_searchParams`)

  const setState_URL_SessionStorage = (value: URLSearchParamsInit) => {
    const newValue = new URLSearchParams(value)

    if (isSafeURL(newValue)) {
      // Normal use case, 99% of the time
      setSearchParams(newValue)
      setQuery("")
    } else {
      // * This code would be for us to keep searchId in the URL
      // * Problem is it's causing many problems with navigation and applied filters
      // ! _start_

      // const toURL = newValue.has("searchId")
      //   ? { searchId: newValue.get("searchId") as string }
      //   : ""

      // setSearchParams(toURL, {
      //   replace: true,
      // })

      // ! _end_

      setSearchParams("", { replace: true })
      setQuery(newValue.toString())
    }
  }

  const result = useMemo(
    () =>
      searchParams.toString().length > 0
        ? searchParams
        : new URLSearchParams(query),
    [searchParams, query]
  )

  return [result, setState_URL_SessionStorage] as const
}

export function isSafeURL(query: URLSearchParamsInit) {
  const newValue = new URLSearchParams(query)

  return newValue.toString().length < MAXIMUM_URL_LENGTH
}
