import { useParams } from "@remix-run/react"
import invariant from "~/utils/invariant"
import { mapRouteNameToProduct } from "~/components/Filters/schemas"

type Props = {
  enforce?: boolean
}
export const useProduct = ({ enforce = true }: Props = {}) => {
  const params = useParams()
  if (enforce) invariant(params.product)
  return mapRouteNameToProduct(params.product ?? "")
}
